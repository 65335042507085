<template v-slot:no-data>
	<v-card dense>
		<v-row style="margin-top: 4px;">
			<v-col cols="12" lg="12" md="12">
				<v-tabs v-model="currentItem">
					
					<v-tab href="#EntryTunnel"> Ingreso Túnel/Camara</v-tab>
					<!-- <v-tab href="#EntryChamber"> Ingreso Cámara</v-tab> -->
					
				</v-tabs>

				<v-tabs-items v-model="currentItem">

					<v-tab-item :value="'EntryTunnel'">
                        <entry-tunnel />
					</v-tab-item>

					<!-- <v-tab-item :value="'EntryChamber'">
						<enter-chamber />
					</v-tab-item> -->
				</v-tabs-items>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>

	//Component
	import EntryTunnel from './PrfEnterTunnelQR.vue'
    import EnterChamber from './PrfEnterChamberQR.vue'

	export default {
		components: {EntryTunnel, EnterChamber},
		props: {},
		data: () => ({
			currentItem: "tab-Funciones",
		}),
	};
</script>
